/* eslint-disable require-yield */
/* eslint-disable no-console */
import { takeEvery, put } from 'redux-saga/effects';
// import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// import { navigator } from '../../navigation/navigator';
// import authSlice from '../slices/auth.slice';
import Api from '../../services/apis/index';
import loaderSlice from '../slices/loader.slice';
import treeViewSlice from '../slices/treeView.slice';

function* handleError(err: any) {
  if (err?.response) {
    if (err?.response?.status && err?.response?.data?.msg && err?.response?.data?.error) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.message) {
    toast.error(err?.response?.data?.msg);
  }
}
function* getAllClients(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    // const filterData = {
    //   limit: null,
    //   pageNo: null,
    // };
    const response = yield Api.client.getAllClients(values);
    if (response?.data?.data) {
      yield put(treeViewSlice.actions.setAddClientTree(response.data.data.clientData));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getLocationByClientTree(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.location.getAllLocationsByClient(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
      };
      yield put(treeViewSlice.actions.setAddLocationTree(data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getBuildingByLocationTree(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.building.getAllBuildingsInLocation(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        locationIndex: values.locationIndex,
      };
      yield put(treeViewSlice.actions.setAddBuildingByLocationTree(data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoorByDepartment(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.door.getAllDoorsInDepartment(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        locationIndex: values.locationIndex,
        buildingIndex: values.buildingIndex,
        floorIndex: values.floorIndex,
        departmentIndex: values.departmentIndex,
      };
      yield put(treeViewSlice.actions.setAddDoorTree(data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDepartmentByFloor(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.department.getAllDepartmentsInFloor(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        locationIndex: values.locationIndex,
        buildingIndex: values.buildingIndex,
        floorIndex: values.floorIndex,
      };
      yield put(treeViewSlice.actions.setAddDepartmentTree(data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getFloorByBuilding(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.floor.getAllFloorsInBuilding(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        locationIndex: values.locationIndex,
        buildingIndex: values.buildingIndex,
      };
      yield put(treeViewSlice.actions.setAddFloorTree(data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getDeviceByLocationTree(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.device.getAllDevicesInDoor(values.id);
    if (response.data.data) {
      const data = {
        response: response.data.data,
        type: values.type,
        index: values.index,
        locationIndex: values.locationIndex,
        buildingIndex: values.buildingIndex,
        departmentIndex: values.departmentIndex,
        floorIndex: values.floorIndex,
        doorIndex: values.doorIndex,
      };
      yield put(treeViewSlice.actions.setAddDeviceTree(data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getLocationById(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.location.getLocationById(values);
    if (response.data) {
      yield put(treeViewSlice.actions.setLocationById(response.data));
    } else {
      toast.error(response.data.msg);
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getBuildingById(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.building.getBuildingById(values);
    if (response.data) {
      yield put(treeViewSlice.actions.setBuildingById(response.data));
    } else if (response.data === 'No devices found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}

function* getFloorById(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.floor.getFloorById(values);
    if (response.data) {
      yield put(treeViewSlice.actions.setFloorById(response.data));
    } else if (response.data === 'No floors found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}

function* getDepartmentById(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.department.getDepartmentById(values);
    if (response.data) {
      yield put(treeViewSlice.actions.setDepartmentById(response.data));
    } else if (response.data === 'No department found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}

function* getDoorById(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.door.getDoorById(values);
    if (response.data) {
      yield put(treeViewSlice.actions.setDoorById(response.data.data));
    } else if (response.data === 'No door found') {
      toast.error(response.data);
      yield put(loaderSlice.actions.hide());
    } else {
      toast.error('error');
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}
function* getDoorLogs(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.door.getDoorLogs(values);
    if (response.data) {
      yield put(treeViewSlice.actions.setDoorLogs(response.data.data));
    } else {
      toast.error(response.data.msg);
      yield put(loaderSlice.actions.hide());
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error('error');
    yield put(loaderSlice.actions.hide());
  }
}

function* treeViewSaga() {
  yield takeEvery('treeView/getAllClients', getAllClients);
  yield takeEvery('treeView/getLocationByClientTree', getLocationByClientTree);
  yield takeEvery('treeView/getBuildingByLocationTree', getBuildingByLocationTree);
  yield takeEvery('treeView/getDeviceByLocationTree', getDeviceByLocationTree);
  yield takeEvery('treeView/getBuildingById', getBuildingById);
  yield takeEvery('treeView/getFloorById', getFloorById);
  yield takeEvery('treeView/getDepartmentById', getDepartmentById);
  yield takeEvery('treeView/getLocationById', getLocationById);
  yield takeEvery('treeView/getDoorById', getDoorById);
  yield takeEvery('treeView/getDoorLogs', getDoorLogs);
  yield takeEvery('treeView/getDoorByDepartment', getDoorByDepartment);
  yield takeEvery('treeView/getDepartmentByFloor', getDepartmentByFloor);
  yield takeEvery('treeView/getFloorByBuilding', getFloorByBuilding);
}

export default treeViewSaga;
