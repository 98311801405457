/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Location from './location';
import minusIcon from '../../assets/images/icons/negative-icon.svg';
import plusIcon from '../../assets/images/icons/plus-icon.svg';

export default function client(props: any) {
  const [flag, setFlag] = useState(false);
  return (
    <div>
      <div className={`flex justify-between ${flag ? 'active-list' : ''}`}>
        <div className="flex items-center max-w-80 cursor-pointer">
          <div className="text-sm truncate">{props.item.organisation_name}</div>
          <span
            className={`mx-2 border flex-shrink-0 rounded-full w-4 h-4 flex justify-center items-center cursor-pointer ${
              props.item.status === 'active' ? 'border-primary' : 'border-black'
            }`}
            onClick={() => {
              if (props.item.children.length === 0 && !flag) {
                props.handleTreeview(props.item, props.index, '', '', '', '', '');
              }
              setFlag(!flag);
            }}
          >
            <img src={flag ? minusIcon : plusIcon} alt="" />

          </span>
        </div>
        <div className="flex items-center pr-4 justify-between">
          <span className="door-val rounded font-medium bg-gray-200 p-1">
            {props.item.Door_Count}
          </span>
        </div>
      </div>
      {props.item.children.length !== 0 && (
        <ul className={`${!flag ? 'hidden' : ''}`}>
          {props.item.children.map((item: any, locationIndex: any) => (
            <li key={item.id}>
              <Location
                key={item.id}
                item={item}
                index={props.index}
                locationIndex={locationIndex}
                handleTreeview={(
                  item: any,
                  index: any,
                  locationIndex: any,
                  buildingIndex: any,
                  floorIndex: any,
                  departmentIndex: any,
                  doorIndex: any,
                ) =>
                  props.handleTreeview(item, index, locationIndex, buildingIndex, floorIndex, departmentIndex, doorIndex)
                }
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
