import React from 'react';
import { Provider } from 'react-redux';

// eslint-disable-next-line import/no-named-as-default
import Spinner from './components/spinner/spinner';
import Toast from './components/toast/toast';
import store from './redux/store/store';

import Router from './navigation/routes';

function App() {
  return (
    <div className="App">

      <Provider store={store}>
        <Spinner />
        <Toast />
        <Router />
      </Provider>
    </div>
  );
}

export default App;
