/* eslint-disable max-len */
import { takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { navigator } from '../../navigation/navigation';
import loaderSlice from '../slices/loader.slice';
import authSlice from '../slices/auth.slice';
import Api from '../../services/apis/index';

// function* handleUnverified(email: string) {
//   try {
//     toast.success('Please Verify your Email');
//     navigator.history.replace('/verifyEmail', { email });
//     yield put(loaderSlice.actions.hide());
//   } catch (err) {
//     yield put(loaderSlice.actions.hide());
//     toast.error(err.message);
//   }
// }

function* login(action: PayloadAction<{ username: string; password: string }>) {
  const { password, username } = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.auth.signIn({ admin_email: username, password });
    if (response.data.data.accessToken) {
      localStorage.setItem('token', response.data.data.accessToken);
      yield put(authSlice.actions.setAuthenticatedUser(response.data.data[0]));
      navigator.history.replace('/dashboard/home');
    } else {
      yield put(authSlice.actions.setAuthentication(false));
      yield put(loaderSlice.actions.hide());
      toast.error('Invalid username or password');
    }
    // if (response?.challengeName === 'NEW_PASSWORD_REQUIRED') {
    //   navigator.history.replace('/ChangePassword', { username });
    // } else {
    //   const currentUserInfo = yield call([Auth, 'currentUserInfo']);
    //   yield put(authSlice.actions.setAuthenticatedUser(currentUserInfo));
    //   navigator.history.replace('/dashboard/overview');
    // }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(authSlice.actions.setAuthentication(false));
    yield put(loaderSlice.actions.hide());
    toast.error('Invalid username or password');
  }
}
function* signUp(action: PayloadAction<{ email: string; password: string, confirmationToken:string }>) {
  const { password, email, confirmationToken } = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    yield Api.auth.signUp({ email, password, confirmationToken });
    yield put(loaderSlice.actions.hide());
    navigator.history.replace('/login');
    toast.success('Successfully signed up');
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(loaderSlice.actions.hide());
    toast.error('Invalid email or confirmation token');
  }
}
function* logout() {
  try {
    yield put(authSlice.actions.clearAuthentication());
    localStorage.clear();
    navigator.history.replace('/login');
  } catch (err) {
    toast.error('error');
  }
}

// const changePassword = (username: string, password: string, newPassword: string) => {
//   new Promise((resolve, reject) => {
//     Auth.signIn(username, password)
//       .then((user) => {
//         if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
// eslint-disable-next-line max-len
//           const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
//           Auth.completeNewPassword(
//             user, // the Cognito User Object
//             newPassword, // the new password
//           ).then((user) => {
//             resolve(user);
//           }).catch((e) => {
//             reject(e);
//           });
//         } else {
//           // other situations
//         }
//       }).catch((e) => {
//         reject(e);
//       });
//   });
// };

function* checkauth() {
  const pathName = navigator?.history?.location?.pathname;
  try {
    yield put(loaderSlice.actions.show());
    const token = localStorage.getItem('token');
    if (token) {
      yield put(authSlice.actions.setAuthentication(true));
      if (!pathName.match('/dashboard')) {
        navigator.history.replace('/dashboard/home');
      }
      // navigator.history.replace(pathName);
      yield put(loaderSlice.actions.hide());
    } else {
      if (pathName.match('/dashboard')) {
        navigator.history.replace('/login');
        toast.error('Please Login');
      }

      yield put(authSlice.actions.setAuthentication(false));
      // navigator.history.replace("/login")
      yield put(loaderSlice.actions.hide());
    }
    // const currentUserInfo = yield call([Auth, 'currentUserInfo']);
    // if (!pathName.match('/dashboard')) {
    //   navigator.history.replace('/dashboard/overview');
    // }
  } catch (err) {
    if (pathName.match('/dashboard')) {
      navigator.history.replace('/login');
    }
    yield put(authSlice.actions.setAuthentication(false));
    // navigator.history.replace("/login")
    yield put(loaderSlice.actions.hide());
    toast.error(err.message);
  }
}

// function* forgotpassword(action: PayloadAction<{ email: string }>) {
//   try {
//     yield put(loaderSlice.actions.show());
//     const { email } = action.payload;
//     yield call([Auth, 'forgotPassword'], email);
//     navigator.history.replace('/resetPassword', { email });
//     yield put(loaderSlice.actions.hide());
//   } catch (err) {
//     yield put(loaderSlice.actions.hide());
//     toast.error('Invalid Email ID');
//   }
// }

// function* verifyOtp(action: PayloadAction<{ email: string; otp: string; password: string; }>) {
//   try {
//     const { otp, email } = action.payload;
//     yield put(loaderSlice.actions.show());
//     yield call([Auth, 'confirmSignUp'], email, otp);
//     navigator.history.replace('/login');
//     toast.success('Verified successfully. Please login again');
//     yield put(loaderSlice.actions.hide());
//   } catch (err) {
//     yield put(loaderSlice.actions.hide());
//     toast.error(err.message);
//   }
// }

// eslint-disable-next-line max-len
// function* forgotpasswordSubmit(action: PayloadAction<{ email: string; code: string; password: string; }>) {
//   try {
//     const { password, code, email } = action.payload;
//     yield put(loaderSlice.actions.show());
//     yield call([Auth, 'forgotPasswordSubmit'], email, code, password);
//     navigator.history.replace('/login');
//     toast.success('Successfully password changed. Please login again');
//     yield put(loaderSlice.actions.hide());
//   } catch (err) {
//     yield put(loaderSlice.actions.hide());
//     toast.error(err.message);
//   }
// }

// function* resendOtp(action: PayloadAction<{ email: string; }>) {
//   try {
//     yield put(loaderSlice.actions.show());
//     const { email } = action.payload;
//     yield call([Auth, 'resendSignUp'], email);
//     yield put(loaderSlice.actions.hide());
//     toast.success('OTP send successfully. Please check your email');
//   } catch (err) {
//     yield put(loaderSlice.actions.hide());
//     toast.error(err.message);
//   }
// }
function* getcurrentAdminuser() {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.auth.getCurrentUser();

    yield put(authSlice.actions.setAuthenticatedUser(response.data.data[0]));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error(err.message);
    yield put(loaderSlice.actions.hide());
  }
}
function* authSaga() {
  yield takeEvery('auth/login', login);
  yield takeEvery('auth/logout', logout);
  yield takeEvery('auth/checkauth', checkauth);
  yield takeEvery('auth/getcurrentAdminuser', getcurrentAdminuser);
  yield takeEvery('auth/signUp', signUp);

  // yield takeEvery('auth/changetemporarypassword', changetemporarypassword);
  // yield takeEvery('auth/forgotpassword', forgotpassword);
  // yield takeEvery('auth/resendOtp', resendOtp);
  // yield takeEvery('auth/forgotpasswordSubmit', forgotpasswordSubmit);
  // yield takeEvery('auth/verifyOtp', verifyOtp);
}

export default authSaga;
