/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
import React from 'react';
import './location.scss';
import Treeview from '../../components/treeview/treeview';
import LocationDetails from '../../components/detail-pages/location-details';
import BuildingDetails from '../../components/detail-pages/building-details';
import FloorDetails from '../../components/detail-pages/floor-details';
import DepartmentDetails from '../../components/detail-pages/department-details.';
import DoorDetails from '../../components/detail-pages/door-details';
import Dashboard from '../dashboard/dashboard';
import Clients from '../clients/clients';

const Locations = ({ match }:any) => {
  const pathName:any = window.location.pathname;
  return (
        <div className="w-full float-left pt-6">
            <div className="sidebar float-left">
                <div className="font-bold text-2xl text-black pb-2 flex justify-between items-center">
                    <div>My Locations</div>
                </div>
                <Treeview bg name="Clients" />
            </div>
            <div className="md:pl-6 location-block pb-4">
            {pathName === '/dashboard/home' && <Dashboard />}
                {pathName === '/dashboard/Clients' && <Clients />}
                {match?.path === '/dashboard/location/:locationId' && <LocationDetails item={match.params} />}
                {match?.path === '/dashboard/building/:buildingId' && <BuildingDetails item={match.params} />}
                {match?.path === '/dashboard/floor/:floorId' && <FloorDetails item={match.params} />}
                {match?.path === '/dashboard/department/:departmentId' && <DepartmentDetails item={match.params} />}
                {match?.path === '/dashboard/door/:doorId' && <DoorDetails item={match.params} />}
            </div>
        </div>
  );
};

export default Locations;
