/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getAllDoorsInDepartment(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getDoorByDepartment`, {
    params: {
      departmentId: values,
    },
  });
}

function* getDoorById(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getDoorById`, {
    params: {
      doorId: values,
    },
  });
}
function* getDoorLogs(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getDoorLogs?limit=5&pageNo=1`, {
    params: {
      doorId: values,
    },
  });
}
const door = {
  getAllDoorsInDepartment,
  getDoorById,
  getDoorLogs,
};

export default door;
