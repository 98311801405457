/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import './client-popup.scss';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
import { createClientFormSchema } from '../../../services/validation';
import clientListSlice from '../../../redux/slices/client.slice';

const initialValues = {
  organizationName: '',
  adminName: '',
  adminEmail: '',
};
const ClientPopup = ({ hasModalView, closeModal }: any) => {
  const formikRef: any = useRef();
  const dispatch = useDispatch();
  const onSubmit = (values: typeof initialValues) => {
    dispatch(clientListSlice.actions.createClient(values));
    closeModal();
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createClientFormSchema}
    >
      {({
        handleSubmit, values, handleChange, handleBlur, errors, touched,
      }) => (
        <div>
          <div
            className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${
              hasModalView ? 'block' : 'hidden'
            }`}
          >
            <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
              <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
                <div onClick={() => closeModal()} className="popup-close absolute top-6 right-5 cursor-pointer">
                  <img src={closeIcon} />
                </div>
                <div className="popup-title font-bold text-2xl">Create Client</div>
                <div className="popup-frm w-full float-left pt-4 pb-2">
                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Organization Name</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Organization Name"
                      type="text"
                      name="organizationName"
                      value={values.organizationName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {errors.organizationName && touched.organizationName && <div style={{ color: 'red' }}>{errors.organizationName}</div>}
                  </div>
                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Admin Name</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Admin Name"
                      type="text"
                      name="adminName"
                      value={values.adminName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {errors.adminName && touched.adminName && <div style={{ color: 'red' }}>{errors.adminName}</div>}
                  </div>
                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Admin Email</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Admin Email"
                      type="text"
                      name="adminEmail"
                      value={values.adminEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                    />
                    {errors.adminEmail && touched.adminEmail && <div style={{ color: 'red' }}>{errors.adminEmail}</div>}

                  </div>

                  <div className="popup-btn flex w-full justify-end mt-5">
                    <button
                      className="rounded-full py-2 px-4 font-semibold text-sm text-white h-11 bg-primary focus:outline-none"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
    </Formik>

  );
};
export default ClientPopup;
