/* eslint-disable react/destructuring-assignment */
import React from 'react';
import unionIconGreen from '../../assets/images/icons/union-green.svg';

export default function Device(props: any) {
  return (
    <div className="flex justify-between items-center px-4 py-1">
      <div className="flex items-center">
        <div className="text-xs">
          Device ID

        </div>
      </div>
      <div className="flex items-center">
        <span className="text-xs">{props.item.sensor_id}</span>
        <img src={unionIconGreen} className="union-icon ml-1" alt="" />
      </div>
    </div>
  );
}
