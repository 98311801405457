/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Building from './building';
import minusIcon from '../../assets/images/icons/negative-icon.svg';
import plusIcon from '../../assets/images/icons/plus-icon.svg';

export default function location(props: any) {
  const pathName:any = window.location.pathname;

  const handleClick = (e:any) => {
    const linkDisabled = pathName === '/dashboard/home';
    if (linkDisabled) e.preventDefault();
  };
  const [flag, setFlag] = useState(false);
  return (
    <div>
      <div className={`flex justify-between ${flag ? 'active-list' : ''}`}>
        <div className="flex items-center max-w-80 cursor-pointer">
          <div className="text-sm truncate">{props.item.location_name}</div>
          <NavLink
            onClick={handleClick}
            to={`/dashboard/location/${props.item.id}`}
            activeClassName="active-menu"
            className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
          >
            <span
              className={`mx-2 border flex-shrink-0 rounded-full w-4 h-4 flex justify-center items-center cursor-pointer ${
                props.item.status === 'active' ? 'border-primary' : 'border-black'
              }`}
              onClick={() => {
                if (props.item.children.length === 0 && !flag) {
                  props.handleTreeview(props.item, props.index, props.locationIndex, '', '', '', '');
                }
                setFlag(!flag);
              }}
            >
              <img src={flag ? minusIcon : plusIcon} alt="" />

            </span>
          </NavLink>
        </div>
        <div className="flex items-center pr-4 justify-between">
          <span className="door-val rounded font-medium bg-gray-200 p-1">
            {props.item.Door_Count}
          </span>
        </div>
      </div>
      {props.item.children.length !== 0 && (
        <ul className={`${!flag ? 'hidden' : ''}`}>
          {props.item.children.map((item: any, buildingIndex: any) => (
            <li key={item.id}>
              <Building
                item={item}
                index={props.index}
                locationIndex={props.locationIndex}
                buildingIndex={buildingIndex}
                handleTreeview={(
                  item: any,
                  index: any,
                  locationIndex:any,
                  buildingIndex: any,
                  floorIndex: any,
                  departmentIndex: any,
                  doorIndex: any,
                ) =>
                  props.handleTreeview(item, index, locationIndex, buildingIndex, floorIndex, departmentIndex, doorIndex)
                }
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
