/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import auth from '../apis/auth/auth';
import client from '../apis/client/client';
import building from '../apis/building/building';
import door from '../apis/door/door';
import location from '../apis/location/location';
import device from '../apis/device/device';
import user from '../apis/users/user';
import dashboard from '../apis/dashboard/dashboard';
import floor from '../apis/floor/floor';
import department from '../apis/department/department';

const Api = {
  auth,
  client,
  building,
  door,
  location,
  device,
  user,
  dashboard,
  floor,
  department,
};

export default Api;
