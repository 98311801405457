/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {

  dashboardInfo: any;
  dashboardGraphData:any

  };

const initialState: InitialState = {
  dashboardInfo: [],
  dashboardGraphData: [],

};
export const getDashboardInfo = () => {};
export const setDashboardInfo = (state: InitialState, action: any) => {
  state.dashboardInfo = action.payload;
};
export const getDashboardGraphData = (state: InitialState, action: any) => {
};
export const setDashboardGraphData = (state: InitialState, action: any) => {
  state.dashboardGraphData = action.payload;
};
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardInfo,
    getDashboardGraphData,
    setDashboardInfo,
    setDashboardGraphData,
  },
});

export default dashboardSlice;
