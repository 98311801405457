/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Formik } from 'formik';
import { useFormik } from 'formik';

// import Select from 'react-select';

import { RootState } from '../../../redux/store/rootReducer';
import clientSlice from '../../../redux/slices/client.slice';
import deviceSlice from '../../../redux/slices/device.slice';
import CustomSelect from '../../CustomSelect';

import './assign-device.scss';
import closeIcon from '../../../assets/images/icons/close-icon.svg';

const AssignPopup = (props: any) => {
  const { hasModalView, closeModal } = props;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      id: '',
      client_id: '',
    },
    onSubmit: (values) => {
      // eslint-disable-next-line no-console
      try {
        dispatch(deviceSlice.actions.attachDevice(
          {
            id: props.doorId,
            client_id: values.client_id,
          },
        ));
        closeModal();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    },
  });

  let ClientList: any = [];

  const getClientList = useSelector((state: RootState) => state.client.client_lists);

  useEffect(() => {
    const filterData = {
      limit: null,
      pageNo: null,
    };
    dispatch(clientSlice.actions.getAllClients(filterData));
  }, []);

  ClientList = getClientList && getClientList.map((item: any) => ({
    value: `${item.id}`,
    label: `${item.admin_name}`,
  }));
  // const [dropdownID, setDropdownID] = useState(ClientList[0]);

  return (
    <div>
      <div className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${hasModalView ? 'block' : 'hidden'}`}>
        <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
          <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative">
            <div onClick={() => closeModal()} className="popup-close absolute top-6 right-5 cursor-pointer"><img src={closeIcon} /></div>
            <div className="popup-title font-bold text-2xl">Assign Device</div>
            <div className="popup-frm w-full float-left pt-4 pb-2">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col mb-1">
                  <label className="font-medium text-sm">Search Client to Assign Devices</label>
                  <CustomSelect
                    options={ClientList}
                    value={formik.values.client_id}
                    onChange={(value: { value: any; }) => formik.setFieldValue('client_id', value.value)}
                  />
                  {/* <Select
                  // isMulti
                      name="Select Client"
                      options={ClientList}
                      value={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                      className="assign-device-select focus:outline-none"
                      classNamePrefix="select"
                    /> */}
                </div>
                <p className="mb-3 font-normal text-sm text-black">You can assign the device to only one client at a time</p>
                <div className="popup-btn flex w-full justify-end mt-5">
                  <button
                    className="uppercase rounded-full py-2 px-4 font-semibold text-sm text-white h-11 bg-primary focus:outline-none"
                    type="submit"
                  >
                    Assign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignPopup;
