import Axios from 'axios';
import authSlice from '../../redux/slices/auth.slice';
import store from '../../redux/store/store';

const request = Axios.create();

request.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    'x-access-token': token,
  };
  return config;
});
request.interceptors.response.use((response) => response, (error) => {
  if (!error.response) {
    // network error
    // eslint-disable-next-line no-param-reassign
  } else if (error.response.status === 401) {
    store.dispatch(authSlice.actions.logout());
  // place your reentry code
  }
  // else if (error.response.status === 409) {
  //   toast.error(err?.response?.data?.msg);
  // }

  throw error;
});
// eslint-disable-next-line import/prefer-default-export
export { request };
