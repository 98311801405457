/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Centrifuge from 'centrifuge';
import treeViewSlice from '../../redux/slices/treeView.slice';
import { RootState } from '../../redux/store/rootReducer';
import './detail-page.scss';

const DoorDetails = (props:any) => {
  // const [doorLogsState, setdoorLogsState] = useState([]);

  // const doorLogs:any = [];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec',
  ];
  // const centrifuge = new Centrifuge('ws://localhost:8000/connection/websocket');
  // centrifuge.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3MjYxMGMwZS0xOTVlLTQxYzEtYjI2OC1kMzFjZThjN2JhMjciLCJjaGFubmVsIjoiJGhlYXZ5ZG9vciIsImlhdCI6MTYxNzE4MzAzNH0.-KvHWllr13LBePCZtYkIwD3lbU-j4gH9NZJdg7DZ3HM');

  // centrifuge.on('connect', (ctx) => {
  //   console.log('connected', ctx);
  // });

  // centrifuge.on('disconnect', (ctx) => {
  //   console.log('disconnected', ctx);
  // });

  // centrifuge.subscribe('heavydoor', (ctx) => {
  //   if (doorLogs.length > 10) {
  //     doorLogs.shift();
  //     doorLogs.push(ctx.data.payload);
  //   } else {
  //     doorLogs.push(ctx.data.payload);
  //   }
  //   console.log('doorLogs', doorLogs);
  //   setdoorLogsState(doorLogs);
  //   console.log('doorLogsState', doorLogsState);
  // });

  // centrifuge.connect();
  const dispatch = useDispatch();
  const { doorByIdResponse, doorLogs } = useSelector(
    (state: RootState) => state.treeView,
  );
  useEffect(() => {
    dispatch(treeViewSlice.actions.getDoorById(props.item.doorId));
    dispatch(treeViewSlice.actions.getDoorLogs(props.item.doorId));
  }, [props.item]);
  const [tabType, setTabType] = useState('activity');
  return (
    <div className="door-details-wrapper">
      <div className="flex flex-wrap items-center justify-between">
        <div>
          <div className="flex items-center flex-wrap">
            <div className="text-lg font-bold">Door : {doorByIdResponse.door_name}</div>
            <div className="text-sm font-normal ml-4 online flex-shrink-0">Online</div>
            <a className="open-anchor flex justify-center ml-4 flex-shrink-0 font-normal leading-snug rounded px-2 cursor-pointer">
              {doorByIdResponse.door_state || 'Not available'}
            </a>
          </div>
          <p className="text-color-gray text-sm">
            Devices:
            {' '}
            <i className="text-black not-italic">{doorByIdResponse.device_count}</i>
            <span className="px-2">|</span>
            Building:
            {' '}
            <i className="text-black not-italic">{doorByIdResponse.building_name}</i>
            <span className="px-2">|</span>
            Location:
            {' '}
            <i className="text-black not-italic">{doorByIdResponse.location_name}</i>
            <span className="px-2">|</span>
            Device ID:
            {' '}
            <i className="text-black not-italic">{doorByIdResponse.device_id}</i>
          </p>
        </div>
        <div>
          <p className="text-color-gray text-sm">
            Assignee:
            {' '}
            <i className="text-black not-italic">{doorByIdResponse?.assignees && (doorByIdResponse?.assignees[0] ? doorByIdResponse?.assignees[0].name : 'Not assigned')}</i>
            <span className="more-assign">{doorByIdResponse.assignees && doorByIdResponse.assignees.length > 1 && (`+${doorByIdResponse?.assignees?.length - 1} `)}</span>
          </p>
        </div>
      </div>
      <div className="w-full mt-3">
        <div className="device-table w-full float-left overflow-auto rounded-xl rounded-b-none">
          <table className="w-full border border-gray-300">
            <thead>
              <tr>
                <th>Sensors</th>
                <th>Last Opened</th>
                <th>Last Message</th>
              </tr>
            </thead>
            <tbody>
              {(doorByIdResponse.deviceData
              && doorByIdResponse.deviceData.map(
                (item: any, index: number) => (
                  <tr>
                    <td>Sensor: {item.sensor_id}</td>
                    <td>{item.last_activity ? item.last_activity : 'N/A'}</td>
                    <td>{item.last_message ? item.last_message : 'N/A'}</td>
                  </tr>
                ),
              )) || 'No sensors found'}

            </tbody>
          </table>
        </div>
      </div>

      <div className="w-full float-left mt-3">
        <div className="log-wrapper w-full float-left px-5 pt-4 pb-5 rounded-xl">
          <div className="w-full float-left border-b border-gray-300 font-medium">
            <div className={`log-tab-title float-left pb-1 relative ${tabType === 'activity' ? 'active-tab' : ''}`} onClick={() => { setTabType('activity'); }}>Activity Log</div>
            <div className={`log-tab-title float-left pb-1 relative ml-4 ${tabType === 'door' ? 'active-tab' : ''}`} onClick={() => { setTabType('door'); }}>Door Log</div>
          </div>
          <div className="w-full float-left pb-2 pt-3 log-content-wrapper">
            <div className={`w-full float-left log-scroll activity-log ${tabType === 'activity' ? 'block' : 'hidden'}`}>
              {doorByIdResponse.activityData
              && doorByIdResponse.activityData.map(
                (item: any, index: number) => (
                  <div className="w-full flex log-list">
                    <div className="w-12 flex flex-col items-end font-normal text-sm flex-shrink-0 py-2 whitespace-nowrap">
                      <span>{new Date(item.created_at).getMinutes()}:{new Date(item.created_at).getSeconds()}</span>
                      <span className="font-bold">{monthNames[new Date(item.created_at).getMonth()]} {new Date(item.created_at).getDate()} </span>
                    </div>
                    <div className="flex items-center text-sm font-normal border-l border-gray-300 flex-grow ml-3 pl-3 py-1 relative has-dot-center">
                      {item.message}
                    </div>
                  </div>
                ),
              )}

            </div>
            <div className={`w-full float-left log-scroll door-log ${tabType === 'door' ? 'block' : 'hidden'}`}>
              {doorLogs?.length && doorLogs.length > 0
              && doorLogs.map(
                (item: any, index: number) => (
                  <div key={item.id} className={`w-full flex log-list ${item.status === 'closed' ? 'status-closed' : 'status-opened'}`}>
                    <div className="w-12 flex flex-col items-end font-normal text-sm flex-shrink-0 py-2 whitespace-nowrap">
                      <span>{new Date(item.created_at).getMinutes()}:{new Date(item.created_at).getSeconds()}</span>
                      <span className="font-bold">{monthNames[new Date(item.created_at).getMonth()]} {new Date(item.created_at).getDate()} </span>

                    </div>
                    <div className="flex items-center text-sm font-normal border-l border-gray-300 flex-grow ml-3 pl-3 py-1 relative has-dot-center">
                      <p>Door {item.status}</p>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default DoorDetails;
