/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../pages/login/login';
import SignUp from '../pages/signUp/signUp';

import { setTopLevelNavigation } from './navigation';
import authSlice from '../redux/slices/auth.slice';
import { RootState } from '../redux/store/rootReducer';
import MainRoute from '../components/layout/mainRoute';

export default function Routes() {
  const dispatch = useDispatch();
  const { initialAuthentcationRequest, authenticated } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (!initialAuthentcationRequest) {
      dispatch(authSlice.actions.checkauth());
    }
  }, []);
  return (
    <Router ref={setTopLevelNavigation}>
      {
        initialAuthentcationRequest === true && authenticated === false
          ? (
            <>
              <Route path="/login" render={() => <Login />} />
              <Route path="/signUp" render={() => <SignUp />} />
            </>
          )
          : null
      }
      {
        initialAuthentcationRequest === true && authenticated === true
          ? <Route path="/dashboard" render={() => <MainRoute />} />
          : null
      }
      <Route exact path="/" render={() => <Login />} />
    </Router>
  );
}
