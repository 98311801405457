import {
  takeEvery, put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import loaderSlice from '../slices/loader.slice';
import Api from '../../services/apis/index';
import clientSlice from '../slices/client.slice';

// eslint-disable-next-line require-yield
function* handleError(err: any) {
  if (err?.response) {
    if (err?.response?.status && err?.response?.data?.msg && err?.response?.data?.error) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.message) {
    toast.error(err?.response?.data?.msg);
  }
}
function* createClient(action: any) {
  try {
    const {
      organizationName,
      adminEmail,
      adminName,
    } = action.payload;
    yield put(loaderSlice.actions.show());

    yield Api.client.createClient({
      name: adminName,
      email: adminEmail,
      organisation_name: organizationName,
    });
    yield put(loaderSlice.actions.hide());
    toast.success('Client added successfully');
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* logInAsClient(action: any) {
  try {
    const {
      // eslint-disable-next-line camelcase
      client_id,
    } = action.payload;
    yield put(loaderSlice.actions.show());

    const response = yield Api.client.logInAsClient({
      // eslint-disable-next-line object-shorthand
      client_id: client_id,
    });
    yield put(clientSlice.actions.setClientToken(response.data.data.token));
    yield put(loaderSlice.actions.hide());
    toast.success('Logged in to client successfully');
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* getAllClients(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.client.getAllClients(values);
    yield put(clientSlice.actions.setClientList(response.data.data.clientData));
    yield put(clientSlice.actions.setClientCount(response.data.data.clientCount));
    yield put(loaderSlice.actions.hide());
    // toast.success('aquired clients successfully');
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}

function* clientSaga() {
  yield takeEvery('client_list/createClient', createClient);
  yield takeEvery('client_list/logInAsClient', logInAsClient);
  yield takeEvery('client_list/getAllClients', getAllClients);
}

export default clientSaga;
