/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable padded-blocks */
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import './user-model-form.scss';
import closeIcon from '../../../../assets/images/icons/close-icon.svg';
import userListSlice from '../../../../redux/slices/adminUsers.slice';
import { inviteUserFormSchema } from '../../../../services/validation';

const initialValues = {
  user_email: '',
  user_name: '',
};
const AddUserModal = ({ hasModalView, closeModal }: any) => {
  const formikRef: any = useRef();
  const dispatch = useDispatch();
  const onSubmit = (values: typeof initialValues) => {
    dispatch(userListSlice.actions.inviteAdminUser(values));
    closeModal();
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={inviteUserFormSchema}
    >
      {({
        handleSubmit, values, handleChange, handleBlur, errors, touched,
      }) => (
        <div>
          <div
            className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${
              hasModalView ? 'block' : 'hidden'
            }`}
          >
            <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
              <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
                <div
                  onClick={() => closeModal()}
                  className="popup-close absolute top-6 right-5 cursor-pointer"
                >
                  <img src={closeIcon} />
                </div>
                <div className="popup-title font-bold text-2xl">Invite User</div>
                <div className="popup-frm w-full float-left pt-4 pb-2">
                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Email</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Email"
                      type="text"
                      name="user_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_email}
                    />
                    {errors.user_email && touched.user_email && <div style={{ color: 'red' }}>{errors.user_email}</div>}
                  </div>

                  <div className="flex flex-col mb-3">
                    <label className="font-medium text-sm">Name</label>
                    <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter Name"
                      type="text"
                      name="user_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_name}
                    />
                    {errors.user_name && touched.user_name && <div style={{ color: 'red' }}>{errors.user_name}</div>}

                  </div>
                  <div className="popup-btn flex w-full justify-end mt-5">
                    <button
                      type="submit"
                      className="rounded-full w-36 py-2 px-4 font-semibold text-sm text-white h-11 bg-primary uppercase focus:outline-none"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Send Invite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default AddUserModal;
