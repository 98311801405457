import React from 'react';
import { Switch, Route } from 'react-router-dom';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */

import LayoutDashboard from './layoutDashboard';
import Layout from './layout';

const MainRoute = () => (

  <Switch>
    <Route path="/dashboard/home">
      <LayoutDashboard />
    </Route>

    <Route path="*">
      <Layout />
    </Route>
  </Switch>

);
export default MainRoute;
