/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Door from './door';
import plusIcon from '../../assets/images/icons/plus-icon.svg';
import minusIcon from '../../assets/images/icons/negative-icon.svg';

export default function Department(props: any) {
  const pathName:any = window.location.pathname;

  const handleClick = (e:any) => {
    const linkDisabled = pathName === '/dashboard/home';
    if (linkDisabled) e.preventDefault();
  };
  const [flag, setFlag] = useState(false);
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center max-w-80 cursor-pointer">
          <div className="text-sm truncate">{props.item.department_name}</div>
          <NavLink
            onClick={handleClick}
            to={`/dashboard/department/${props.item.id}`}
            activeClassName="active-menu"
            className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
          >
            <span
              className="mx-2 border flex-shrink-0 rounded-full w-4 h-4 flex justify-center items-center border-black cursor-pointer"
              onClick={() => {
                if (props.item.children.length === 0 && !flag) {
                  props.handleTreeview(
                    props.item,
                    props.index,
                    props.locationIndex,
                    props.buildingIndex,
                    props.floorIndex,
                    props.departmentIndex,
                    '',
                  );
                }
                setFlag(!flag);
              }}
          >
              <img src={flag ? minusIcon : plusIcon} alt="" />
            </span>
          </NavLink>
        </div>
        <div className="flex items-center pr-4 justify-between">
          <span className="door-val rounded font-medium bg-gray-200 p-1">
            {props.item.Door_Count}
          </span>
        </div>
      </div>
      {props.item.children.length !== 0 && (
        <ul className={`${!flag ? 'hidden' : ''}`}>
          {props.item.children.map((item: any, doorIndex: any) => (
            <li key={item.id}>
              <Door
                handleTreeview={(
                  item: any,
                  index: any,
                  locationIndex:any,
                  buildingIndex: any,
                  floorIndex: any,
                  departmentIndex: any,
                  doorIndex: any,
                ) =>
                  props.handleTreeview(item, index, locationIndex, buildingIndex, floorIndex, departmentIndex, doorIndex)
                }
                item={item}
                index={props.index}
                locationIndex={props.locationIndex}
                floorIndex={props.floorIndex}
                departmentIndex={props.departmentIndex}
                doorIndex={doorIndex}
                buildingIndex={props.buildingIndex}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
