/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './detail-page.scss';
// import assignIcon from '../../assets/images/icons/assign.svg';
import downArrowIcon from '../../assets/images/icons/down-arrow.svg';
import upArrowIcon from '../../assets/images/icons/up-arrow.svg';
import treeViewSlice from '../../redux/slices/treeView.slice';
import { RootState } from '../../redux/store/rootReducer';

const BuildingDetails = (props: any) => {
  const dispatch = useDispatch();
  const { departmentByIdResponse } = useSelector((state: RootState) => state.treeView);
  const doorList: any = new Array(2);
  const unAssignedDoorList: any = new Array(3);

  doorList.fill(1);
  unAssignedDoorList.fill(1);
  const [dropdownID, setDropdownID] = useState(null);
  useEffect(() => {
    dispatch(treeViewSlice.actions.getDepartmentById(props.item.departmentId));
  }, [props.item]);
  const dropChange = (id: any) => {
    if (dropdownID !== id) {
      setDropdownID(id);
    } else {
      setDropdownID(null);
    }
  };

  return (
    <div>
      <div className="flex items-center pt-1">
        <div className="text-lg font-bold">
          Department:
          {departmentByIdResponse?.departmentDetails?.department_name}
        </div>
        <p className="text-color-gray text-sm ml-4">
          Doors:
          {departmentByIdResponse.door_count}
          <span className="px-2">|</span>
          Location:
          {' '}
          {departmentByIdResponse?.location_name}
        </p>
      </div>
      <div className="w-full mt-3 float-left">
        <div className="w-full md:w-2/4 xl:w-5/12 md:pr-3 float-left">
          <p className="font-medium text-base text-color-dark">Doors awaiting maintenance</p>
          <div className="location-box-wrapper mt-2">
            {departmentByIdResponse.doors_for_maintenance &&
              departmentByIdResponse.doors_for_maintenance.map((item: any, index: number) => (
                <div key={index} className="location-box w-full p-2 bg-white rounded-md mb-3">
                  <div className="flex border-b px-2 py-4 items-center">
                    <div className="flex-grow overflow-hidden">
                      <p className="truncate text-base font-bold leading-snug">{item.door_name}</p>
                    </div>
                    <a className="opening-anchor flex-shrink-0 font-medium text-xs leading-snug rounded py-1 px-2">
                      {item.openings_count}
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full md:w-2/4 xl:w-5/12 md:pl-3 float-left">
          <p className="font-medium text-base text-color-dark">Unassigned Doors in department</p>
          <div className="location-box-wrapper mt-2">
            {departmentByIdResponse.unassigned_doors_in_department &&
              departmentByIdResponse.unassigned_doors_in_department.map((item: any, index: number) => (
                <div key={index} className="location-box w-full p-2 bg-white rounded-md mb-3">
                  <div className="flex px-2 py-4 items-center">
                    <div className="flex-grow overflow-hidden">
                      <p className="truncate text-base font-bold leading-snug">{item.door_name}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full building-list-wrapper float-left mt-4 overflow-auto">
          <div className="building-list-inner w-full float-left">
            <div className="building-list-title flex text-sm font-bold py-1 pl-3 pr-0">
              <div className="w-p-13 px-1">Doors</div>
              <div className="w-p-13 px-1">Device Status</div>
              <div className="w-p-18 px-1">Last Opened</div>
              <div className="w-p-16 px-1">Total Openings</div>
              <div className="w-p-22 px-1">Assigned To</div>
              {/* <div className="flex-grow px-1">Maintainer</div> */}
            </div>

            <div className="building-list-container">
              {departmentByIdResponse.door_data &&
                departmentByIdResponse.door_data.map((door: any, index: number) => (
                  <div key={door.key} className="building-list border mb-2">
                    <div
                      onClick={() => {
                        dropChange(index);
                      }}
                      className="flex text-sm cursor-pointer font-normal py-3 pl-3 pr-0 items-center break-words"
                    >
                      <div className="w-p-13 px-1">{door.door_name}</div>
                      <div className="w-p-13 px-1 ">
                        {' '}
                        {door.status || 'N/A'}
                      </div>
                      <div className="w-p-18 px-1">{door.last_used || 'Not available'}</div>
                      <div className="w-p-16 px-1">{door.openings_count || 'Not available'}</div>
                      <div className="w-p-22 px-1">
                        {door.door_users.length > 0 ? (
                          <div>
                            {door.door_users[0].client_user.personnel.name}
                            <span className="more-assign">
                              {door.door_users.length > 1 &&
                                `+
                              ${door.door_users.length - 1} `}
                            </span>
                          </div>
                        ) : (
                          <span>not available</span>
                        )}
                      </div>
                      {/* <div className="flex-grow px-1">Nathaline David</div> */}
                      <div className="w-10 flex-shrink-0 flex justify-center">
                        <img src={dropdownID === index ? upArrowIcon : downArrowIcon} alt="" />
                      </div>
                    </div>
                    <div className={`building-list-info ${dropdownID !== index ? 'hidden' : ''}`}>
                      {door.sensors && (door.sensors.length > 0 ?
                        door.sensors.map((item: any) => (
                          <div key={item.id} className="flex py-3 px-3 border-t border-gray-300 break-words">
                            <div className="text-sm font-normal text-gray-500 w-p-26 px-1">
                              Sensors:
                              {' '}
                              {item.sensor_id}
                            </div>
                            <div className="text-sm font-normal text-gray-500 w-p-18 px-2">
                              {item.last_activity || 'Not available'}
                            </div>
                            <div className="text-sm font-normal text-gray-500 flex-grow px-2">
                              {item.last_message || 'Not available'}
                            </div>
                          </div>
                        )) : (
                          <div className="flex py-3 px-3 border-t border-gray-300 break-words">
                            <div className="text-sm font-normal text-gray-500 w-p-26 px-1">
                              <span>
                                No sensors available
                              </span>
                            </div>

                          </div>

                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingDetails;
