/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Device from './device';
import plusIcon from '../../assets/images/icons/plus-icon.svg';
import minusIcon from '../../assets/images/icons/negative-icon.svg';
import treeViewSlice from '../../redux/slices/treeView.slice';

export default function Door(props: any) {
  const dispatch = useDispatch();

  const pathName:any = window.location.pathname;
  const setKeys = () => {
    dispatch(treeViewSlice.actions.setKeys(props.item));
    dispatch(treeViewSlice.actions.setKeysPopUp(true));
  };
  const handleClick = (e:any) => {
    const linkDisabled = pathName === '/dashboard/home';
    if (linkDisabled) e.preventDefault();
  };
  const [flag, setFlag] = useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center max-w-50 cursor-pointer">
          <div className="text-sm truncate">{props.item.door_name}</div>
          <NavLink
            onClick={handleClick}
            to={`/dashboard/door/${props.item.id}`}
            activeClassName="active-menu"
            className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
          >
            <span
              className="mx-2 border flex-shrink-0 rounded-full w-4 h-4 flex justify-center items-center border-black cursor-pointer"
              onClick={() => {
                if (props.item.children.length === 0 && !flag) {
                  props.handleTreeview(
                    props.item,
                    props.index,
                    props.locationIndex,
                    props.buildingIndex,
                    props.floorIndex,
                    props.departmentIndex,
                    props.doorIndex,
                  );
                }
                setFlag(!flag);
              }}
            >
              <img src={flag ? minusIcon : plusIcon} alt="" />
            </span>
          </NavLink>

        </div>

        <div className="flex items-center pr-4 justify-between">
          <button type="button" onClick={setKeys} className="view-key border border-gray-400 text-xs py-1 px-2 rounded mr-3">
            {' '}
            View key
          </button>

        </div>
      </div>
      {props.item.children.length !== 0 && (
        <div className={`${!flag ? 'hidden' : ''}`}>
          {props.item.children.map((item: any, index: any) => (
            <Device key={item.id} item={item} index={index} />
          ))}
        </div>
      )}
    </div>
  );
}
