/* eslint-disable require-yield */
/* eslint-disable camelcase */
import {
  takeEvery, put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import loaderSlice from '../slices/loader.slice';
import Api from '../../services/apis/index';
import userListSlice from '../slices/adminUsers.slice';

// eslint-disable-next-line require-yield
function* handleError(err: any) {
  if (err?.response) {
    if (err?.response?.status && err?.response?.data?.msg && err?.response?.data?.error) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.message) {
    toast.error(err?.response?.data?.msg);
  }
}
function* getAllAdminUsers(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: object = action.payload;
    const response = yield Api.user.getAllAdminUsers(values);
    yield put(userListSlice.actions.setuserList(response.data.data));
    // yield put(userListSlice.actions.setuserListCount(response.data.data.totalCount));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    toast.error(err.message);
    yield put(loaderSlice.actions.hide());
  }
}
function* inviteAdminUser(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const {
      // eslint-disable-next-line camelcase
      user_email,
      user_name,
    } = action.payload;

    let args = {};

    args = {
      user_email,
      user_name,
    };
    yield Api.user.inviteAdminUser(args);
    yield put(loaderSlice.actions.hide());
    yield put(userListSlice.actions.getAllAdminUsers({
      limit: 10,
      pageNo: 1,
      search: '',
    }));

    toast.success('User added successfully');
  } catch (err) {
    yield handleError(err);

    yield put(loaderSlice.actions.hide());
    // toast.error(error.message);
  }
}
function* removeUser(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const {
      // eslint-disable-next-line camelcase
      user_id,
    } = action.payload;

    let args = {};

    args = {
      user_id,
    };
    yield Api.user.removeUser(args);
    yield put(userListSlice.actions.getAllAdminUsers({
      limit: 10,
      pageNo: 1,
      search: '',
    }));

    yield put(loaderSlice.actions.hide());
    toast.success('User removed successfully');
  } catch (error) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}
function* resendInvite(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const {
      // eslint-disable-next-line camelcase
      admin_user_id,
    } = action.payload;
    let args = {};
    args = {
      admin_user_id,
    };
    yield Api.user.resendInvite(args);
    yield put(loaderSlice.actions.hide());
    toast.success('Invite sent Successfully');
  } catch (error) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.message);
  }
}
function* adminUsersSaga() {
  yield takeEvery('userList/inviteAdminUser', inviteAdminUser);
  yield takeEvery('userList/getAllAdminUsers', getAllAdminUsers);
  yield takeEvery('userList/removeUser', removeUser);
  yield takeEvery('userList/resendInvite', resendInvite);
}

export default adminUsersSaga;
