/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getAllAdminUsers = (state: any, action: any) => state;
const setuserList = (state: any, action: any) => {
  state.user_lists = action.payload;
};
const setuserListCount = (state: { user_listcount: any; }, action: { payload: any; }) => {
  state.user_listcount = action.payload;
};
const inviteAdminUser = (state: any, action: any) => state;
const removeUser = (state: any, action: any) => state;
const resendInvite = (state: any, action: any) => state;

const initialState = {
  user_lists: [],
  user_listcount: '',
};

const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    removeUser,
    getAllAdminUsers,
    setuserList,
    setuserListCount,
    inviteAdminUser,
    resendInvite,
  },
});

export default userListSlice;
