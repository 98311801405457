/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line no-unused-vars
const getAllClients = (state: any, action: any) => state;

const setClientList = (state: any, action: any) => {
  state.client_lists = action.payload;
};

const getClientCount = (state: any, action: any) => state;

const setClientCount = (state: any, action: any) => {
  state.client_list_count = action.payload;
};
const createClient = (state: any, action: any) => state;

const logInAsClient = (state: any, action: any) => state;

const setClientToken = (state: any, action: any) => {
  state.client_token = action.payload;
};

const initialState = {
  client_lists: [],
  client_list_count: '',
  client_token: '',
};

const clientListSlice = createSlice({
  name: 'client_list',
  initialState,
  reducers: {
    createClient,
    getAllClients,
    setClientList,
    getClientCount,
    setClientCount,
    logInAsClient,
    setClientToken,
  },
});

export default clientListSlice;
