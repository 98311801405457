/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  authenticated: boolean;
  initialAuthentcationRequest: boolean;
  user: {
    id: string;
    email: string;
  } | undefined
}

const initialState: InitialState = {
  user: undefined,
  authenticated: false,
  initialAuthentcationRequest: false,
};

// eslint-disable-next-line max-len
export const login = (state: InitialState, action: PayloadAction<{ password: string, username: string }>) => state;

export const signUp = (state: InitialState, action: PayloadAction<{ password: string, email: string, confirmationToken:string }>) => state;

export const logout = (state: InitialState) => state;

export const checkauth = (state: InitialState) => state;

export const setAuthenticatedUser = (state: InitialState, action: PayloadAction<any>) => {
  state.user = action.payload;
  state.authenticated = true;
  state.initialAuthentcationRequest = true;
};

export const setAuthentication = (state: InitialState, action: PayloadAction<boolean>) => {
  state.authenticated = action.payload;
  state.initialAuthentcationRequest = true;
};

// // eslint-disable-next-line max-len
// export const forgotpassword = (state: InitialState, action: PayloadAction<{ email: string; }>) => state;

// // eslint-disable-next-line max-len
// export const forgotpasswordSubmit = (state: InitialState, action: PayloadAction<{ email: string; password: string; code:string; }>) => state;

export const clearAuthentication = (state: InitialState) => {
  state.authenticated = false;
  state.initialAuthentcationRequest = true;
  state.user = undefined;
};

// export const verifyOtp = (state: InitialState, action: PayloadAction<{ otp: string;email: string }>) => state;

// export const updateFCMToken = (state: InitialState) => state;

// export const resendOtp = (state: InitialState, action: PayloadAction<{ email: string }>) => state;
const getcurrentAdminuser = (state: any, action: any) => state;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login,
    logout,
    checkauth,
    setAuthenticatedUser,
    setAuthentication,
    clearAuthentication,
    getcurrentAdminuser,
    signUp,
    // forgotpassword,
    // forgotpasswordSubmit,
    // clearAuthentication,
    // verifyOtp,
    // updateFCMToken,
    // resendOtp,
  },
});

export default authSlice;
