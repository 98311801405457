/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* inviteAdminUser(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/inviteAdminUser`, data);
}
function* getAllAdminUsers(values:any) {
  let search = 'search=';
  if (values.search && typeof values.search === 'string') {
    search = `search=${encodeURIComponent(values.search)}`;
  }
  return yield request.get(
    `${APP_ENTRYPOINT}/admin/getAllAdminUsers?limit=${values.limit}&pageNo=${values.pageNo}&${search}`,
  );
}
function* removeUser(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/removeUser`, data);
}
function* resendInvite(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/resendInvite`, data);
}
const user = {
  inviteAdminUser,
  getAllAdminUsers,
  removeUser,
  resendInvite,
};

export default user;
