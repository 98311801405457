/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* signIn(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/adminSignin`, data);
}
function* signUp(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/adminUserSignUp`, data);
}
function* getCurrentUser() {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getAdminById`);
}

const auth = {
  signIn,
  getCurrentUser,
  signUp,
};

export default auth;
