import {
  takeEvery, put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import loaderSlice from '../slices/loader.slice';
import dashboardSlice from '../slices/dashboard.slice';
import Api from '../../services/apis/index';

// eslint-disable-next-line require-yield
function* handleError(err: any) {
  if (err?.response) {
    if (err?.response?.status && err?.response?.data?.msg && err?.response?.data?.error) {
      toast.error(err?.response?.data?.msg);
    }
  } else if (err.msg) {
    toast.error(err?.response?.data?.msg);
  }
}
function* getDashboardInfo() {
  try {
    yield put(loaderSlice.actions.show());
    const response = yield Api.dashboard.getDashboardData();
    if (response.data) {
      yield put(dashboardSlice.actions.setDashboardInfo(response.data));
    } else {
      toast.error('error');
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* getDashboardGraphData(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.dashboard.getDashboardGraphData(values);
    if (response.data) {
      yield put(dashboardSlice.actions.setDashboardGraphData(response.data.data));
    } else {
      toast.error('error');
    }
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield handleError(err);
    yield put(loaderSlice.actions.hide());
  }
}
function* dashboardSaga() {
  yield takeEvery('dashboard/getDashboardInfo', getDashboardInfo);
  yield takeEvery('dashboard/getDashboardGraphData', getDashboardGraphData);
}

export default dashboardSaga;
